<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalReviveReservation.Header_ModalHeader')
    "
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="reviveReservation"
    :showModal="showModal"
    :buttonLabelConfirm="
      $t('Components.Reservation.ModalReviveReservation.Label_Yes')
    "
    :buttonLabelCancel="
      $t('Components.Reservation.ModalReviveReservation.Label_No')
    "
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <Message
      class="is-info has-margin-bottom"
      :message="$t('Components.Reservation.ModalReviveReservation.Text_Revive')"
    />
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    Message
  },
  
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {},

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    reviveReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .reviveReservation(this.reservation.Id)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              // Update reservation store
              self.setReservation(response.data)

              // Set timer to close popup in in 1,5 seconds
              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
